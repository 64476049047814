export const SHARE_VIA_WHATSAPP = 'share_via_whatsapp_contest_web';
export const SHARE_VIA_TELEGRAM = 'share_via_telegram_contest_web';
export const SHARE_VIA_CONTEST = 'share_via_contest_web';
export const CONTEST_CARD_CLICK = 'contest_card_click_web';
export const CONTEST_CARD_VISIBLE = 'contest_card_visible_web';
export const CONTEST_JOINED = 'contest_joined_web';
export const CONTEST_AUTH_FAILED = 'contest_auth_failed_web';
export const CONTEST_DETAILS_JOIN_NOW = 'contest_details_join_now_web';
export const CONTEST_DETAILS_GO_TO_OFFERS = 'contest_details_go_to_offers_web';
export const CONTEST_ERROR_BOUNDARY = 'contest_error_boundary_web';
export const GLOBAL_ERROR_BOUNDARY = 'global_error_boundary_web';
export const OFFER_CARD_CLICK = 'offer_click';
export const PROFILE_OFFER_CARD_CLICK = 'profile_offer_click';
export const OFFER_CARD_VIEW = 'offer_view';
export const OFFER_LOCAL_VERIFIED = 'offer_local_verified';
export const OFFER_CTA_CLICKED = 'offer_cta_clicked';
export const USAGE_PERMISSION_REQUESTED = 'usage_permission_requested';
export const USAGE_PERMISSION_ACCEPTED = 'usage_permission_accepted';
export const USAGE_PERMISSION_REJECTED = 'usage_permission_rejected';
export const OFFER_404 = 'offer_404';
export const HELP_PAGE_OPEN = 'help_page_open';
export const SORT_UPDATED = 'sort_updated';
export const OFFER_CTA_CLICKED_V2 = 'offer_cta_clicked_v2';
export const FILTER_CLICKED = 'filter_chip_clicked';
export const SORT_CHIP_CLICKED = 'sort_chip_clicked';
export const FILTER_UPDATED = 'filter_updated';
export const USER_VOTE_INITIATED = 'user_vote_initiated';
export const USER_VOTE_SUBMITTED = 'user_vote_submitted';
export const OFFER_DETAILS_SHOW = 'offer_details_show';
export const OFFER_DETAILS_UNMOUNT = 'offer_details_unmount'


/* Log events for Video tutorial */
export const VIDEO_VIEW = 'video_view' // total view count
export const VIDEO_FORWARD_SKIP = 'video_forward_skip'
export const VIDEO_BACKWARD_SKIP = 'video_backward_skip'
export const VIDEO_PLAYBACK_ERROR = 'video_playback_error'
export const USER_VIDEO_COMPLETION = 'user_video_completion'
export const VIDEO_PAUSE = 'video_pause'
export const VIDEO_RESUME = 'video_resume'
export const VIDEO_ENDED = 'video_ended'
export const VIDEO_PROGRESS_ON_CLICK = 'video_progress_on_click'
// export const USER_VIDEO_PAUSE = 'user_video_pause' // count of video paused by user
// export const VIDEO_FIRST_QUARTILE = 'video_first_quartile' // count of user viewing 25% video
// export const VIDEO_MID_QUARTILE = 'video_mid_quartile' // count of user viewing 50% video
// export const VIDEO_LAST_QUARTILE = 'video_last_quartile' // count of user viewing 75% video
// export const VIDEO_COMPLETE_VIEW = 'video_complete_view' // count of user viewing full video


/* AUTH  */
export const USER_SIGNUP_REFERRAL = 'user_signup_referral'
export const USER_SIGNUP = 'user_signup'
export const USER_LOGIN = 'user_login'
export const USER_SIGNUP_INVALID_REFERRAL = 'user_signup_invalid_referral'
